import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-spells"></a><h2>Epic Spells</h2>
    <a id="what-are-epic-spells"></a><h5>WHAT ARE EPIC SPELLS? </h5>
Epic spells are spells developed from the ground up using a list of
magical ingredients called seeds. Despite their power, epic spells
still follow the basic rules for casting spells, except as specifically
noted otherwise. Epic casters can manipulate the seeds of true magic,
but knowing the seeds and how to manipulate them does not instantly
grant ultimate power. Each epic spell must be laboriously developed
before it can be used. 
    <a id="acquiring-epic-spells"></a><h5>ACQUIRING EPIC SPELLS </h5>
A character with the Epic Spellcasting feat may start acquiring epic
spells immediately. Using epic spells is a two-step procedure:
development and spellcasting. 
    <a id="epic-spell-development"></a><h5>EPIC SPELL DEVELOPMENT </h5>
Before it can be cast, an epic spell must be developed. The process of
development can be a time-consuming and expensive process. It is during
development that a caster determines whether a given epic spell lies
within his or her abilities or beyond them. The basis of that
determination lies in an epic spell&#8217;s Spellcraft DC. 
    <p>The easiest way to develop an epic spell is to use one already
given. The description of each of these unique spells gives the amount
of gold, time, and experience points required to develop the spell. If
a character pays a spell&#8217;s development cost, he or she develops (and
thus knows) that spell. </p>
    <p>For information on developing an epic spell completely from
scratch, see Developing Unique Epic Spells, below. </p>
    <a id="epic-spellcasting"></a><h5>EPIC SPELLCASTING </h5>
Once an epic spell is developed, the caster knows the spell. A
developed epic spell becomes an indelible part of the caster and may be
prepared without a spellbook (if a wizard is the caster). Characters
who cast spells spontaneously, such as sorcerers, can cast a developed
epic spell by using any open epic spell slot. Druids, clerics, and
similar spellcasters can likewise prepare epic spells using epic spell
slots. 
    <p>A spellcaster can prepare or cast any epic spell he or she
knows as many times per day as he or she has available epic spell
slots. A spellcaster who can cast epic spells has a number of open epic
spell slots per day equal to one-tenth his or her ranks in the
Knowledge skill appropriate to the spell and the caster&#8217;s class.
Knowledge (arcana) is appropriate for arcane casters, and Knowledge
(religion) or Knowledge (nature) is appropriate for divine casters. The
rules for rest between casting a day&#8217;s allotment of epic spells are the
same as for rest required to prepare standard spells. If the caster
doesn&#8217;t use up a day&#8217;s allotment of epic spell slots, the unused slots
remain available whether or not the spellcaster receives appropriate
rest. </p>
    <p>Even if the epic spell has been developed and an epic spell
slot is available, successfully casting an epic spell isn&#8217;t assured.
The caster&#8217;s Spellcraft skill modifier is vital for casting an epic
spell. To cast an epic spell, a spellcaster makes a Spellcraft check
against the epic spell&#8217;s Spellcraft DC. If the check succeeds, the
spell is cast. If the caster fails the check, the epic spell fizzles
and the epic spell slot is used for the day. </p>
    <p>Because epic spells require Spellcraft checks, a spell is
beyond the caster&#8217;s ability if the final Spellcraft DC is greater than
20 + the spellcaster&#8217;s Spellcraft modifier. Epic spells with DCs higher
than 10 + the spellcaster&#8217;s Spellcraft modifier are risky; a caster can
take 10 when casting an epic spell, but he or she can&#8217;t take 20. When
routinely casting epic spells, most spellcasters take 10 on their
Spellcraft checks. </p>
    <a id="epic-spell-levels"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Spell Levels:</span>
Epic spells have no fixed level. However, for purposes of Concentration
checks, spell resistance, and other possible situations where spell
level is important, epic spells are all treated as if they were
10th-level spells. </p>
    <a id="metamagic-items-and-epic-spells"></a><p><span style={{
        "fontWeight": "bold"
      }}>Metamagic, Items, and Epic
Spells:</span> Metamagic feats and other epic feats that manipulate
normal spells cannot be used with epic spells. </p>
    <p>A character can&#8217;t craft a magic item that casts an epic spell,
regardless of whether the item is activated with spell completion, a
spell trigger, a command word, or simple use. Only major artifacts,
which are beyond the means of even epic characters to create, can
possibly contain magic of this power. </p>
    <p>The saving throw against a character&#8217;s epic spell has a DC of
20 + the character&#8217;s relevant ability score modifier. It&#8217;s possible to
develop epic spells that have even higher DCs, however, by applying the
appropriate factor. </p>
    <a id="epic-spell-terms"></a><h5>EPIC SPELL TERMS</h5>
    <a id="epic-spell"></a><b>Epic Spell:</b> Spells that are different from common spells. Epic spells
are usually custom-made. Epic spells do not take up normal spell slots,
but instead are gained and used under a completely separate
progression. 
    <a id="epic-spell-slots"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Spell Slots:</span> A
character must have an available epic spell slot to prepare or cast an
epic spell, just as he or she needs a normal spell slot for a nonepic
spell. A character doesn&#8217;t gain epic spell slots by virtue of his or
her level and class, however. A character gets one epic spell slot for
every 10 ranks he or she has in the relevant Knowledge skill. </p>
    <a id="factor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Factor:</span> When creating
an epic spell, a character can modify the basic use of a seed. Each
modification is called a factor, and most factors increase the
difficulty of casting the spell. </p>
    <a id="mitigating-factor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mitigating Factor:</span> The
opposite of a normal factor, a mitigating factor modifies the spell but
makes it easier to cast. </p>
    <a id="seed"></a><p><span style={{
        "fontWeight": "bold"
      }}>Seed:</span> Every custom
epic spell created by spellcasters begins with a base effect called a
seed. Seeds are the fundamental building blocks of epic spells. </p>
    <a id="spellcraft-dc"></a><p><span style={{
        "fontWeight": "bold"
      }}>Spellcraft DC:</span> For
epic spells, the Spellcraft DC is a measure of how difficult the spell
is for a spellcaster to cast. It also measures how powerful an epic
spell is. </p>
    <p>
    </p>
    <a id="epic-spell-formulas-and-calculations"></a><h5>EPIC SPELL FORMULAS AND CALCULATIONS </h5>
The following formulas are important to epic spellcasters. 
    <a id="epic-spells-per-day"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Spells Per Day:</span>
Knowledge (arcana), Knowledge (religion), or Knowledge (nature) ranks
&divide; 10 (round down). </p>
    <a id="to-cast-an-epic-spell"></a><p><span style={{
        "fontWeight": "bold"
      }}>To Cast an Epic Spell:</span>
Spellcraft check (DC = epic spell&#8217;s Spellcraft DC). </p>
    <a id="level-of-an-epic-spell"></a><p><span style={{
        "fontWeight": "bold"
      }}>Level of an Epic Spell:</span>
Epic spells are considered 10th level for the purpose of Concentration
checks, spell resistance, and other determinations. </p>
    <a id="saving-throw-for-an-epic-spell"></a><p><span style={{
        "fontWeight": "bold"
      }}>Saving Throw for an Epic
Spell:</span> DC = 20 + key ability modifier. </p>
    <a id="dispelling-epic-spell-and-antimagic-field"></a><h5>DISPELLING,EPIC SPELLS,AND ANTIMAGIC FIELD</h5>
A lucky nonepic spellcaster casting greater dispel magic might be able
to dispel an epic spell. The game mechanics do not change, and epic
spells do not occupy any privileged position allowing them to resist
being dispelled other than their presumably high caster level.
Likewise, epic spells using the dispel seed can dispel nonepic spells.
Such epic spells use the same game mechanic: The check to dispel is
1d20 + a specified number (usually dispeller&#8217;s level), and the DC is 11
+ the spellcaster&#8217;s level. 
    <p>Antimagic field does not automatically suppress epic spells as
it does standard spells. Instead, each time an epic spell is subject to
an antimagic field, make a dispel check as a 20th-level caster (1d20 +
20). The epic spell has a DC of 11 + the epic spell&#8217;s spellcaster
level. If the suppression check is successful, the epic spell is
suppressed like any other spell. If the dispel check is unsuccessful,
the epic spell functions normally.</p>
    <a id="epic-spell-descriptions"></a><h3>EPIC SPELL DESCRIPTIONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Each epic spell description follows the same format used for 0- to
9th-level spells. There are two additional entries for epic spells:
Spellcraft DC and To Develop. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Spellcraft DC:</span> This
is the DC of the Spellcraft check required to cast the epic
spell. When casting an epic spell, you gain a +5 bonus on
your Spellcraft check if the base seed of the epic spell is
from your arcane school specialty or primary psionic
discipline. You take a �15 penalty if the epic spell seed is
from your prohibited arcane school.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>To Develop:</span> The first
part of this entry shows the resources in gold, time, and experience
points a character must expend to develop the spell shown. If the
character expends the resources, he or she develops the spell if he or
she has access to all the seeds. Spells containing the life or heal
seed are typically only available to those with 24 or more ranks in
Knowledge (religion) or Knowledge (nature). The rest of the development
entry details the seeds and factors used to create the epic spell. This
information is provided as an example for characters when they attempt
to create and develop their own unique epic spells. </p>
    <h6><a id="animus-blast"></a>Animus Blast </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Cold]</span> 
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 50 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 20-ft.-radius
hemisphere burst 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 450,000 gp; 9
days; 18,000 XP. Seeds: energy (DC 19), animate dead (DC 23). Factors:
set undead type to skeleton (-12 DC), 1-action casting time (+20 DC). 
    <p>When this spell is cast, enemies within range are dealt 10d6
points of cold damage. However, up to twenty of those victims that
perish as a result of this blast are then instantly animated as Medium
skeletons. These skeletons serve the character indefinitely. The
character cannot exceed the normal limit for controlling undead through
use of this spell, but other means that allow the character to exceed
the normal limit for controlled undead work just as well with undead
created with animus blast. </p>
    <a id="animus-blizzard"></a><h6>Animus Blizzard </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Cold] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 78 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 20-ft.-radius
hemisphere burst
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 702,000 gp;
15 days; 28,080 XP. Seeds: energy (DC 19), animate dead (DC 23).
Factors: increase damage to 30d6 (+40 DC), set undead type to wight (-4
DC). 
    <p>When this spell is cast, enemies within range are dealt 30d6
points of cold damage. However, up to five victims that perish as a
result of this blast are then instantly animated as wights. These five
wights serve the character indefini tely. The character cannot exceed
the normal limit for controlling undead through use of this spell, but
other means that allow the character to exceed the normal limit for
controlled undead work just as well with undead created with animus
blizzard. </p>
    <h6><a id="contingent-resurrection"></a>Contingent Resurrection
    </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Healing) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 52 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , D F
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You or creature
touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Contingent
until expended, then instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 468,000 gp;
10 days; 18,720 XP. Seed: life (DC 27). Factor: activates when subject
is slain (+25 DC). 
    <p>Contingent resurrection returns the subject to life if he or
she is slain. Once cast, the spell remains quiescent and does not
activate until the trigger conditions have been met (but each day it
remains untriggered, it uses up an epic spell slot, even if the
character cast it on another creature). Once triggered, the spell is
expended. If the subject is killed (the trigger), he or she is restored
to life and complete health 1 minute later, so long as even a tiny bit
of dust remains for contingent resurrection to act upon. A shaft of
light shines down from the heavens, illuminating the subject and
everything within 20 feet. The creature is restored to full hit points,
vigor, and health, with no loss of prepared spells. However, the
subject loses one level (or 1 point of Constitution if the subject was
1st level). Contingent resurrection does not work on a creature that
has died of old age. </p>
    <h6><a id="create-living-vault"></a>Create Living Vault (Ritual)</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 58 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 100 days,
11 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One living vault,
50 ft. by 50 ft. by 10 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 540,000
gp; 11 days; 21,600 XP. Seeds: animate (DC 25) large
chunk of stone, fortify (DC 27). Factors: allow vault to
�grow� to proper size in 4d4 days (ad hoc +20 DC),
increase HD of object by 92 (+184 DC), grant magical
immunity (ad hoc +105 DC), increase damage reduction
to 30 (+29 DC) and to /+7 (+18 DC), make permanent
(x5 DC). Mitigating factors: increase casting time by 10
minutes (�20 DC), increase casting time by 100 days (�
200 DC), 16d6 backlash (�16 DC), seven additional
casters contributing one epic spell slot (�133 DC), four
additional casters contributing one 2nd-level spell slot (�
12 DC), one additional caster contributing one 1st-level
spell slot (�1 DC), burn 20,000 XP per epic caster (�
1,600 DC).
    <p>The character creates a construct known as a living vault to
protect and hide his or her treasures. Upon completion, the vault
initially measures only 5 feet on a side, but it gradually increases to
its proper size over the following 4d4 days. The vault is attuned to
the character, allowing him or her alone entrance and egress in a
manner similar to a dimension door spell. When the character desires
the vault to hide itself, he or she gives it a simple command. To
summon the vault, the character may cast a sending spell or arrange
some other manner to contact it. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 20,000 XP. </p>
    <h6><a id="crown-of-vermin"></a>Crown of Vermin </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 56 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Aura of one
thousand insects that surrounds you in a 10-ft.-radius spread 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 504,000 gp;
11 days; 20,160 XP. Seeds: summon (DC 14), fortify (DC 17). Factors:
summon vermin mass instead of one creature (ad hoc +8 DC), grant damage
reduction 1/epic (+15 DC), allow mass to move at your speed (ad hoc +2
DC), perfect control of vermin (ad hoc +2 DC). Mitigating factor:
change range to personal (-2 DC). 
    <p>After casting crown of vermin, one thousand venomous, biting
and stinging spiders, scorpions, beetles, and centipedes erupt from the
very air around the character. This swarm forms a living aura around
the character to a radius of 10 feet. The character is immune to his or
her own crown of vermin. The swarm goes where the character goes at his
or her speed, even if the character takes to the air or water (though
water drowns the vermin after 1 full round of immersion, unless the
spell is cast underwater, in which case aquatic or marine vermin answer
the call and cannot leave the water). Each vermin in the crown of
vermin bites a creature who enters the area occupied by the effect (or
the character forces the effect into an area occupied by another
creature) for 1 point of damage, and then dies. Each victim takes
enough points of damage to kill it, destroying that number of vermin in
the process. Victims get a Reflex saving throw each round to avoid the
full press, and if successful, take only 10d10 bites (and 10d10 points
of damage). A total of 1,000 points of damage can be dealt to those who
fall prey to the crown of vermin. The vermin have damage reduction
1/epic, so the vermin&#8217;s natural weapons are treated as epic for the
purpose of overcoming damage reduction. If there aren&#8217;t enough vermin
to kill all the creatures in the spell&#8217;s effect, the creature with the
fewest hit points is affected first, then the creature with the second
fewest hit points, and so on. After all creatures that can be killed
have been killed, any remaining damage is distributed among the
survivors equally. </p>
    <p>The character has utter control over the vermin in his or her
aura, and can force them into areas that would normally deter common
vermin. The character can completely suppress his or her vermin aura as
a free action so that no vermin are visible at all. The time that
vermin are suppressed does not count toward the spell&#8217;s duration.
Alternatively, the character can roughly shape and move the vermin in
any fashion he or she desires within the limits of the 10-foot-radius
spread as a move-equivalent action. The vermin cannot be wrested from
the character&#8217;s control through any means. The vermin make all saving
throws to avoid damaging effects using the character&#8217;s base saving
throw bonuses. They gain the character&#8217;s spell resistance, if any, and
they get saving throws against spells that would otherwise
automatically slay vermin. A character can see through his or her crown
of vermin without difficulty, but gains one-half concealment against
enemy attacks launched both outside and within the character&#8217;s crown of
vermin.
    </p>
    <h6><a id="damnation"></a>Damnation </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion)
[Teleportation] [Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 97 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
(20 hours for compulsion) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 873,000 gp;
18 days; 34,920 XP. Seeds: foresee (to preview likely hellscape) (DC
17), transport (DC 27), compel (to keep target in hell) (DC 19).
Factors: interplanar travel (+4 DC), unwilling target (+4 DC), 1-action
casting time (+20 DC), +15 to DC of subject&#8217;s save (+30 DC). Mitigating
factor: burn 2,400 XP (-24 DC). 
    <p>The character sends his or her foe to hell. If the character
succeeds at a melee touch attack, the target must succeed at a Will
saving throw (DC = the standard epic spell DC + 15). If he or she fails
this saving throw, he or she is sent straight to a layer of a lawful
evil plane (or a chaotic evil plane, at the character&#8217;s option)
swarming with fiends. The subject will not willingly leave the plane
for 20 hours, believing that his or her predicament is a just reward
for an ill-spent life. Even after the compulsion fades, he or she must
devise his or her own escape from the plane. Unless the GM devises a
specific location and scenario in the Nine Hells, the subject
encounters a group of 1d4 pit fiends (or balors, if in a chaotic evil
plane) every hour he or she spends in hell. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,000 XP. </p>
    <h6><a id="demise-unseen"></a>Demise Unseen </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Necromancy (Death, Evil),
Illusion (Figment) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 80 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature of
up to 80 HD 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fort
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 738,000 gp;
15 days; 29,520 XP. Seeds: slay (DC 25), animate dead (DC 23), delude
(DC 14). Factors: change undead type to ghoul (-10 DC), apply to all
five senses (+8 DC), 1-action casting time (+20 DC).
    <p>The character instantly slays a single target and at the same
moment animate the body so that it appears that nothing has happened to
the creature. The target&#8217;s companions (if any) do not immediately
realize what has transpired. The target receives a Fortitude saving
throw to survive the attack. If the save fails, the target remains in
its exact position with no apparent ill effects. In reality, it is now
a ghoul under the character&#8217;s control. The target&#8217;s companions notice
nothing unusual about the state of the target until they interact with
it, at which time each companion receives a Will saving throw to notice
discrepancies. The ghoul serves the character indefinitely. The
character cannot exceed the normal limit for controlling undead through
use of this spell, but other means that allow the character to exceed
the normal limit for controlled undead work just as well with undead
created with demise unseen. </p>
    <h6><a id="dire-winter"></a>Dire Winter </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Cold] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 319 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 1,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 1,000-ft.-radius
emanation 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 2,871,000 gp;
58 days; 114,840 XP. Seed: energy (emanate 2d6 cold in 10-ft. radius)
(DC 19). Factor: 100 times increase in base area (+400 DC). Mitigating
factor: burn 10,000 XP (-100 DC). 
    <p>The creature or object targeted emanates bitter cold to a
radius of 1,000 feet for 20 hours. The emanated cold deals 2d6 points
of damage per round against unprotected creatures (the target is
susceptible if not magically protected or otherwise resistant to the
energy). The intense cold freezes water out of the air, causing
constant snowfall and wind. The snow and wind produce a blizzard effect
within the Area. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP. </p>
    <h6><a id="dragon-knight"></a>Dragon Knight (Ritual)</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) [Fire]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 38 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, Ritual 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One summoned
adult red dragon 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 342,000 gp; 7
days; 13,680 XP. Seed: summon (DC 14). Factors: summon creature other
than outsider (+10 DC), summon CR 14 creature (+24 DC), 1-action
casting time (+20 DC). Mitigating factor: two additional casters
contributing 8th-level spell slots (-30 DC). 
    <p>This spell summons an adult red dragon. It appears where the
character designates and acts immediately. It attacks the character&#8217;s
opponents to the best of its abilities (on the first round, it prefers
to breathe fire on an enemy, if possible). The character can direct the
dragon not to attack, to attack particular enemies, or to perform other
actions. This is a ritual spell requiring two other spellcasters, each
of which must contribute an unused 8th-level spell slot to the casting.
    </p>
    <h6><a id="dragon-strike"></a>Dragon Strike (Ritual)</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) [Fire]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 50 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, Ritual,
XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Ten summoned
adult red dragons 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 450,000 gp; 9
days; 18,000 XP. Seed: summon (DC 14). Factors: summon creature other
than outsider (+10 DC), summon CR 14 creature (+24 DC), summon ten
creatures (x10 DC), 1-action casting time (+20 DC). Mitigating factors:
eleven additional casters contributing 9th-level spell slots (-187 DC),
burn 2,000 XP per caster (-240 DC), 3d6 backlash (-3 DC). 
    <p>This spell summons ten adult red dragons. They appear where
the character designates and act immediately. They attack the
character&#8217;s opponents to the best of their abilities (on the first
round, they all prefer to simultaneously breathe fire on an enemy, if
possible). The character can direct the dragons not to attack, to
attack particular enemies, or to perform other actions. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,000 XP
(per caster). </p>
    <h6><a id="dreamscape"></a>Dreamscape </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration [Teleportation] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 29 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You and other
touched willing creatures weighing up to 1,000 lb. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
(D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Yes
(harmless) (see text) S
    <span style={{
      "fontWeight": "bold"
    }}>pell Resistance:</span> Yes
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 261,000 gp; 6
days; 10,400 XP. Seed: transport (DC 27). Factor: transport to region
of dreams (+2 DC). 
    <p>The character and any creatures he or she touches are drawn
into the region of dreams. The character can take more than one
creature along (subject to the character&#8217;s weight limit), but all must
be touching each other. The character physically enters the land of
dreams, leaving nothing behind. For every minute the character moves
through the dream landscape, he or she can &#8220;wake&#8221; to find him or her
self five miles displaced in the waking world. The character does not
know precisely where he or she will come out in the waking world, nor
the conditions of the waking world through which the character travels.
The character knows approximately where he or she will end up based on
time spent traveling in dream. Dreamscape can also be used to travel to
other planes that contain creatures that dream, but doing this requires
crossing into the dreams of outsiders, where the character is subject
to the dangers of alien dream realities. This is a potentially perilous
proposition. Transferring to another plane of existence requires 1d4
hours of uninterrupted journey. Any creatures touched by the character
when dreamscape is cast also make the transition to the borders of
unconscious thought. They may opt to follow the character, wander off
into the dreams of others, or stumble back into the waking world (50%
chance for either of the latter results if they are lost or abandoned
by the character). Creatures unwilling to accompany the character into
the region of dreams receive a Will save, negating the effect if
successful. </p>
    <h6><a id="eclipse"></a>Eclipse </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)
[Transportation]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 42 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 200 miles 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 5-mile radius,
centered on you 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Up to 8 hours
(D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 378,000 gp; 8
days; 15,1200 XP. Seeds: conjure (DC 21), transport (to move disk into
position 100 miles up) (DC 27). Factors: increase mass by 1,000% (+40
DC), spread mass into paper-thin disk (ad hoc +2 DC), keep disk in
place for 8 hours (ad hoc +10 DC). Mitigating factors: increase casting
time by 9 minutes (-18 DC), burn 4,000 XP (-40 DC). 
    <p>With this spell, the character can create a limited eclipse,
as though a heavenly body moves between the sun and the earth. The
landscape within a five-mile radius of the character&#8217;s location
experiences the dimming of the sun as a disk the character creates
passes in front of it, culminating in a complete blackout and
accompanying coronal ring. The eclipse follows the character across the
landscape for up to 8 hours, or until the sun goes down, or until the
character dismisses the eclipse. The character does not need to
concentrate on the eclipse while it lasts. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 4,000 XP. </p>
    <h6><a id="eidolon"></a>Eidolon </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)
[Transportation]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 79 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 5 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One duplicate of
caster 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 8 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 711,000 gp;
15 days; 28,440 XP. Seed: conjure (to make base substance) (DC 21),
transform (DC 21), transport (to move part of caster&#8217;s soul into
duplicate) (DC 27). Factors: nonliving substance to humanoid (+10 DC),
transform into specific individual (+25 DC). Mitigating factor: burn
2,500 XP (-25 DC). 
    <p>Upon casting eidolon, the character creates a duplicate
version of him or her self as the character was when he or she was a
21st-level character, and the character gains one negative level while
the duplicate persists. For each additional negative level the
character bestow upon him or her self at the time of casting, the
eidolon has one additional character level. No matter how many negative
levels the character bestows on him or her self, the eidolon can never
have more character levels than the character has (taking the negative
levels into account). Treat the duplicate as the character with a
number of negative levels conferred that would lower him or her to the
character level of the eidolon. The eidolon is considered fresh and
rested when created. It may cast any spell the character has access to,
including an epic spell. Use the eidolon&#8217;s Spellcraft modifier as the
basis for the number of epic spells it can cast in a day, and its
effective character level as a basis for its skills, feats, and other
abilities. The eidolon is effectively lower level than the character
and probably can&#8217;t cast all the spells he or she knows. A powerful
enough eidolon might conceivably cast the eidolon spell itself. The
eidolon appears in whatever mundane clothing the character desires when
initially conjured, but it has no other possessions. It shares part of
the character&#8217;s soul, so it is the character for all intents and
purposes. The character and his or her eidolon communicate with each
other normally. Usually, the eidolon does not begrudge its brief
existence, because it is still part of the character. If the eidolon is
killed prior to the expiration of the spell&#8217;s duration, the character
immediately regains the lost levels. Normally, the eidolon does not
last long enough to threaten the character with permanent level drain. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,500 XP. </p>
    <h6><a id="enslave"></a>Enslave (Ritual)</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion)
[Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 80
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 720,000 gp;
15 days; 28,800 XP. Seed: compel (DC 19). Factors: stricter compulsion
of any creature (ad hoc +11 DC), 1-action casting time (+20 DC),
permanent (x5 DC). Mitigating factors: 2d6 backlash (-2 DC), four
additional casters contributing one 9th-level spell slot (-68 DC), burn
2,000 XP per caster (-100 DC).
    <p>The character makes a permanent thrall of any living creature.
The character establishes a telepathic link with the subject&#8217;s mind. If
the creature has a language, the character can generally force the
subject to perform as he or she desires, within the limits of its
abilities. If the creature has no language, the character can
communicate only basic commands. The character knows what the subject
is experiencing, but does not receive direct sensory input from it. A
subject forced to take an action against its nature receives a saving
throw with a penalty of -10 to resist taking that particular action,
but if it succeeds, it still remains the character&#8217;s thrall despite its
minor mutiny. Once a subject makes a successful saving throw to resist
a specific order, it makes all future saving throws to resist taking
that specific action without a penalty. Protection from evil or a
similar spell can prevent the character from exercising control or
using the telepathic link while the subject is so protected, but it
does not prevent the establishment of enslave or dispel it. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,000 XP. </p>
    <h6><a id="epic-counterspell"></a>Epic Counterspell </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 69 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature or
object 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 621,000 gp;
13 days; 24,840 XP. Seed: dispel (DC 19). Factors: +30 to dispel check
(+30 DC), 1-action casting time (+20 DC). 
    <p>To use epic counterspell, select an opponent as the target.
The character does this by readying an action, electing to wait to
complete his or her action until the opponent tries to cast a spell
(the character may still move his or her speed, because readying a
counterspell is a standard action). If the target tries to cast a
spell, make a dispel check: Roll d20+40 against a DC of 11 + the foe&#8217;s
caster level. If the check is successful, the character&#8217;s spell negates
the foe&#8217;s spell. </p>
    <h6><a id="epic-mage-armor"></a>Epic Mage Armor </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation) [Force] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 46 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 24 hours (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 414,000 gp; 9
days; 16,560 XP. Seed: armor (DC 14). Factor: +16 additional armor
bonus (+32 DC). 
    <p>An invisible but tangible field of force surrounds the subject
of epic mage armor, providing a +20 armor bonus to Armor Class. Unlike
mundane armor, epic mage armor entails no armor check penalty, arcane
spell failure chance, or speed reduction. Because epic mage armor is
made of force, incorporeal creatures can&#8217;t bypass it the way they do
normal armor. </p>
    <h6><a id="epic-repulsion"></a>Epic Repulsion </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 52 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Object or
creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 468,000 gp;
10 days; 18,720 XP. Seed: ward (DC 14). Factor: permanent &#61480;5 DC).
Mitigating factor: increase casting time by 9 minutes (-18 DC). 
    <p>The character can create a permanent ward against a specific
creature type. Any creature of the specified type cannot attack or
touch the warded creature or object. The protection ends if the warded
creature makes an attack against or intentionally moves to within 5
feet of a specified creature. Spell resistance can allow a creature to
overcome this protection and touch the warded creature. </p>
    <h6><a id="epic-spell-reflection"></a>Epic Spell Reflection </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 68 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 41 days, 11
minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Object or
creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 630,000 gp;
13 days; 25,200 XP. Seed: reflect (DC 27). Factors: reflect up to
9th-level spells (+160 DC), change range to touch (+2 DC), permanent
(x5 DC). Mitigating factors: increase casting time by 10 minutes (-20
DC), increase casting time by 41 days (-82 DC), 20d6 backlash (-20 DC),
six additional casters contributing one 8th-level spell slot (-90 DC),
burn 9,500 XP per caster (-665 DC).
    <p>The character can create a permanent ward against all spells
of 1st through 9th level that target the subject. These spells are
reflected back on the caster. Spells that affect an Area are not
affected by this spell. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 7,500 XP. </p>
    <h6><a id="eternal-freedom"></a>Eternal Freedom </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 150 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, Ritual,
XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Touched creature
or object of 2,000 lb. or less 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 1,350,000 gp;
27 days; 54,000 XP. Seed: ward (DC 14). Factors: specific protections
entangle (+0 DC), hold monster (+8 DC), hold person (+4 DC),
imprisonment (+16 DC), paralysis (ad hoc +6 DC), petrification (ad hoc
+6 DC), sleep (+0 DC), slow (+4 DC), stunning (ad hoc +6 DC), temporal
stasis (+16 DC), and web (+4 DC); permanent &#61480;5 DC). Mitigating factors:
ten additional casters contributing 9th-level spell slots (-170 DC),
burn 10,000 XP (-100 DC). 
    <p>The subject becomes permanently immune to the following
specific spells, effects, and spell-like abilities: entangle, hold,
imprisonment, paralysis, petrification, sleep, slow, stunning, temporal
stasis, and web. This is a ritual spell requiring ten other
spellcasters, each of whom must contribute an unused 9th-level spell
slot to the casting. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP. </p>
    <h6><a id="greater-spell-resistance"></a>Greater Spell
Resistance </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 45 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, Ritual 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 405,000 gp; 9
days; 16,200 XP. Seed: fortify (DC 27). Factor: +10 additional SR (+40
DC). Mitigating factor: two additional casters contributing 6th-level
spell slots (-22 DC). 
    <p>The character grants the subject touched spell resistance 35
until the duration expires. The spell resistance granted does not
stack, but overlaps with, any previous spell resistance. This is a
ritual spell, requiring two other spellcasters, each of whom must
contribute an unused 6th-level spell slot to the casting. </p>
    <h6><a id="greater-ruin"></a>Greater Ruin </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 59 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 12,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature, or
up to a 10-foot cube of nonliving matter 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 531,000 gp;
11 days; 21,240 XP. Seed: destroy (DC 29). Factors: increase damage to
35d6 (+30 DC), 1- action casting time (+20 DC).Mitigating factor: burn
2,000 XP (-20 DC). 
    <p>The character deals 35d6 points of damage to a single target
within range and line of sight. If the target is reduced to -10 hit
points or less (or a construct, object, or undead is reduced to 0 hit
points), it is utterly destroyed as if disintegrated. Only a trace of
fine dust remains. XP Cost: 2,000 XP.</p>
    <h6><a id="hellball"></a>Hellball </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Acid, Fire,
Electricity, Sonic] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 90 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 40-ft.-radius
spread 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 810,000 gp;
17 days; 32,400 XP. Seed: energy (deals 10d6 each of acid, fire,
electricity, sonic) (DC 76). Factors: double base area (+6 DC),
1-action casting time (+20 DC). Mitigating factors: 10d6 backlash (-10
DC), burn 200 XP (-2 DC).
    <p>A hellball deals 10d6 points of acid damage, 10d6 points of
fire damage, 10d6 points of electricity damage, and 10d6 points of
sonic damage to all creatures within the area. Unattended objects also
take this damage. The character takes 10d6 points of damage upon
casting (in addition to burning 400 XP). The character points his or
her finger and determine the range (distance and height) at which the
hellball is to detonate. A sun-bright, fist-sized globe of energy
streaks forth and, unless it impacts a material body or solid barrier
prior to attaining the indicated range, expands into its full area.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 200 XP. </p>
    <h6><a id="kinetic-control"></a>Kinetic Control </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 100 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal; touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You; creature or
object touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 12 hours or
until discharged 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 927,000 gp;
19 days; 37,080 XP. Seeds: ward (5 points against bludgeoning and
piercing) (DC 14), reflect (DC 27). Factors: also against slashing (+4
DC), additional 15 points of protection (+30 DC), contingent reflection
of damage on creature touched (+25 DC). 
    <p>Once a character has cast this spell, he or she can absorb,
store, and redirect the energy contained in any physical (melee or
ranged) attack. The character absorbs 20 points of each separate
slashing, bludgeoning, and piercing attack made against him or her,
saving it for later. A character can absorb up to 150 points of damage
in this fashion; however, if the stored damage is not discharged prior
to reaching the 150-point limit, the spell automatically discharges,
dealing the 150 points of damage to the character. The character keeps
track of the number of points of damage he or she has absorbed (the
character doesn&#8217;t have to keep track of the type of damage). At any
time during the spell&#8217;s duration, the character can make a touch attack
against another creature or object. If successful, the character deals
the target some or all (character&#8217;s choice) of the points of damage he
or she has stored. The damage delivered is considered bludgeoning
damage. A character can absorb and discharge damage any number of times
during the spell&#8217;s duration, so long as the character doesn&#8217;t absorb
more than 150 points at a time. When the spell expires, any stored
damage the character has not redirected is discharged into the
character. </p>
    <h6><a id="let-go-of-me"></a>Let Go of Me </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 43 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 free
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature or
force grappling you 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 387,000 gp; 8
days; 15,480 XP. Seed: destroy (DC 29). Factors: quickened (+28 DC), no
verbal or somatic components (+4 DC). Mitigating factors: limited
circumstance (ad hoc -8 DC), 10d6 backlash (-10 DC). 
    <p>The character deals 20d6 points of damage to any creature
grappling him or her. The damage dealt is of no particular type or
energy-it is a purely destructive impulse. If grappled by a magical
force the force is automatically destroyed.</p>
    <h6><a id="living-lightning"></a>Living Lightning </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Electricity]</span> 
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 140 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. or 150 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> A bolt 5 ft. wide
by 300 ft. long, or 10 ft. wide by 150 ft. long 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 1,260,000 gp;
26 days; 50,400 XP. Seeds: life (DC 27), energy (DC 19). Factors:
1-action casting time (+20 DC), no verbal or somatic components (+4
DC), giving life to a spell (ad hoc x2 DC). 
    <p>The character develops a spell that he or she can cast;
thereafter, the spell can effectively &#8220;cast itself.&#8221; When the character
casts the spell, a stroke of energy deals 10d6 points of electricity
damage to each creature within the spell&#8217;s area. Living lightning
follows all the standard rules for epic spell casting when the
character casts it. Living lightning is sentient and generally friendly
toward the character. It has the character&#8217;s mental ability scores, but
it has no physical ability scores. It senses the world through the
character&#8217;s senses and communicates with him or her by thought. As a
self-triggering spell, it isn&#8217;t truly alive but is a fragment of the
character&#8217;s personality. It cares little for the world around it, but
at the character&#8217;s urging (and sometimes at its own discretion) it
casts itself at his or her foes. Casters who prepare spells before
casting must prepare living lightning normally in order for it to cast
itself. When the spell casts itself, it acts on the character&#8217;s
initiative but does not count against his or her own actions in the
round. The character cannot simultaneously cast living lightning while
it is casting its own effect, even if it has been prepared more than
once. Living lightning uses up one of the character&#8217;s epic spell slots
for the day whenever it casts itself. When the character has used up
all his or her epic spell slots for the day (or has cast all his or her
prepared living lightning spells, if a caster who must prepare spells),
living lightning becomes quiescent. It remains so until the character
has rested to regain his or her epic spell slots for the next day.
    </p>
    <h6><a id="lord-of-nightmares"></a>Lord of Nightmares </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 50 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One summoned
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 450,000 gp; 9
days; 18,000 XP. Seed: summon (DC 14). Factors: summon CR 31 creature
(+58 DC), allow creature to possess body and act at its own discretion
(ad hoc -20 DC), 1-action casting time (+20 DC). Mitigating factors:
12d6 backlash (-12 DC), burn 1,000 XP (-10 DC). 
    <p>The character is possessed by a dream larva. For 20 rounds,
the dream larva&#8217;s body physically replaces the character&#8217;s, though the
dream larva has the character&#8217;s equipment. The dream larva is free to
call on all its own powers and abilities, or use the character&#8217;s
equipment. The character&#8217;s consciousness and physical form are
suppressed for the duration of the possession. The character has no way
to dismiss the spell, communicate, or otherwise maintain awareness once
possession has commenced. The dream larva, temporarily freed from its
imprisonment in some distant nightmare, will attempt to slay and
incapacitate any creature it can see or find, whether it is a friend or
foe of the caster. Casting lord of nightmares entails some risk for the
caster, since it&#8217;s unknown what a dream larva might do over the course
of 20 rounds. The larva will dispatch all enemies it can find before
turning to its own concerns. Sometimes a dream larva will attempt to
place itself in a dangerous or precarious situation prior to the end of
the spell, leaving the caster to extricate him or herself. If the dream
larva is slain during the duration of the spell, the character&#8217;s
consciousness is instantly restored to aware-ness within his or her own
body. The character&#8217;s condition remains what it was when he or she
completed casting lord of nightmares, regardless of what damage the
dream larva received. However, magic item charges used, potions
consumed, and other physical resources used up by the dream larva are
permanent. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 1,000 XP. </p>
    <h6><a id="mass-frog"></a>Mass Frog </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 55 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 40-ft.-radius
hemisphere 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 495,000 gp;
10 days; 19,800 XP. Seed: transform (DC 21). Factors: change target to
area of 20-ft. hemisphere (+10 DC), increase area by 100% (+4 DC), 1
action casting time (+20 DC). 
    <p>This epic spell turns all Medium or smaller creatures in the
area into frogs. The transformed creatures retain their mental
faculties, including personality, Intelligence, Wisdom, and Charisma
scores, level and class, hit points (despite any change in Consitution
score), alignment, base attack bonus, base save bonuses, extraordinary
abilities, spells, and spell-like abilities, but not supernatural
abilities. They assume the physical characteristics of frogs, including
natural size and Strength, Dexterity, and Constitution scores. (Use the
statistics for the toad.) All the creatures&#8217; equipment drops to the
ground upon transformation. </p>
    <h6><a id="momento-mori"></a>Momento Mori </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Necromancy [Death]</span> 
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 86 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 quickened
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
partial (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 774,000 gp;
16 days; 30,960 XP. Seed: slay (DC 25). Factor: increase to 160 HD (+8
DC), quickened (+28 DC), no verbal or somatic components (+4 DC), +10
to DC of subject&#8217;s save (+20 DC). 
    <p>As a free action that counts as a quickened spell, the
character wills the target dead without a word or gesture. The
character&#8217;s thought snuffs out the life force of a living creature of
160 or fewer HD, killing it instantly. The subject is entitled to a
Fortitude saving throw (DC 30 + relevant ability modifier) to have a
chance of surviving the attack. If the save is successful, the target
instead takes 3d6+20 points of damage. </p>
    <h6><a id="mummy-dust"></a>Mummy Dust </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Necromancy [Evil] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 35 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S ,M, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Two 18-HD mummies
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 315,000 gp; 7
days; 12,600 XP. Seed: animate dead (DC 23). Factors: 1-action casting
time (+20 DC). Mitigating factors: burn 400 XP (-4 DC), expensive
material component (ad hoc -4 DC). 
    <p>When the character sprinkles the dust of ground mummies in
conjunction with casting mummy dust, two Large 18-HD mummies (see
below) spring up from the dust in an area adjacent to the character.
The mummies follow the character&#8217;s every command according to their
abilities, until they are destroyed or the character loses control of
them by attempting to control more Hit Dice of undead than he or she
has caster levels. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span>
Specially prepared mummy dust (10,000 gp). </p>
    <p><span style={{
        "textDecoration": "underline"
      }}>XP Cost:</span>
2,000 XP. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Mummy, Advanced:</span> CR 8;
Large undead; HD 18d12+3; hp 120; Init -1; Spd 20 ft.; AC 20, touch 8,
flat-footed 20; Base Atk +9; Grp +24; Atk +20 melee (1d8+16 plus mummy
rot); Full Atk +20 melee (1d8+16 plus mummy rot); Space/Reach 10 ft./10
ft.; SA Despair, mummy rot; SQ Damage reduction 5/-, darkvision 60 ft.,
undead traits, vulnerability to fire; AL LE; SV Fort +8, Ref +7, Will
+13; Str 32, Dex 8, Con --, Int 6, Wis 14, Cha 15. Skills and Feats:
Hide -5, Listen +9, Move Silently +10, Spot +9; Alertness, Blind-Fight,
Great Fortitude, Lightning Reflexes, Power Attack, Toughness, Weapon
Focus (slam).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Despair (Su):</span> At the
sight of a mummy, the viewer must succeed at a Will save (DC 21), or be
paralyzed with fear for 1d4 rounds. Whether or not the save is
successful, that creature cannot be affected again by that mummy&#8217;s
despair ability for one day. Mummy Rot (Su): Supernatural disease-slam,
Fortitude save (DC 21), incubation period 1 minute; damage 1d6 Con and
1d6 Cha. The save DC is Charisma-based. Unlike normal diseases, mummy
rot continues until the victim reaches Constitution 0 (and dies) or is
cured as described below.&nbsp; Mummy rot is a powerful curse, not a
natural disease. A character attempting to cast any conjuration
(healing) spell on a creature afflicted with mummy rot must succeed on
a DC 20 caster level check, or the spell has no effect on the afflicted
character. To eliminate mummy rot, the curse must first be broken with
break enchantment or remove curse (requiring a DC 20 caster level check
for either spell), after which a caster level check is no longer
necessary to cast healing spells on the victim, and the mummy rot can
be magically cured as any normal disease.</p>
    <p>An afflicted creature who dies of mummy rot shrivels away into
sand and dust that blow away into nothing at the first wind.</p>
    <h6><a id="nailed-to-the-sky"></a>Nailed to the Sky </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration [Teleportation] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 62 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S ,XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature or
object weighing up to 1,000 lb. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 558,000 gp;
12 days; 22,320 XP. Seeds: foresee (to preview endpoint of
teleportation) (DC 17), transport (DC 27). Factors: unwilling target
(+4 DC), increase range from touch (+4 DC), 1-action casting time (+20
DC). Mitigating factor: burn 1,000 XP (-10 DC). 
    <p>Nailed to the sky actually places the target so far from the
surface of the world and at such a speed that it keeps missing the
surface as it falls back, so it enters an eternal orbit. Unless the
target can magically fly or has some other form of non-physical
propulsion available, the target is stuck until someone else rescues
it. Even if the target can fly, the surface is 2 to 4 hours away,
assuming a fly spell, which allows a maximum speed of 720 feet per
round while descending. The target may not survive that long. Depending
on the world where nailed to the sky is cast, conditions so far from
its surface may be deadly. Deleterious effects include scorching heat,
cold, and vacuum. Targets subject to these conditions take 2d6 points
of damage each from heat or cold and 1d4 points of damage from the
vacuum each round. The target immediately begins to suffocate. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 1,000 XP. </p>
    <h6><a id="origin-of-species-achaierai"></a>Origin of Species:
Achaierai </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation, Healing)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 38 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, DF, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 100 days,
11 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One constructed
creature up to Medium (20 cu. ft.) 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 360,000 gp; 8
days; 14,400 XP. Seeds: conjure (DC 21), life (DC 27), fortify (DC 17).
Factors: +4 HD (5 hp per HD) (+20 DC), +6 to natural AC (+12 DC), add
three more natural attacks (ad hoc +6 DC), add black cloud spell-like
ability (+33 DC), add SR 19 (+15 DC), permanent (x5 DC). Mitigating
factors: 50d6 backlash (-50 DC), increase casting time by 10 minutes
(-20 DC), increase casting time by 100 days (-200 DC), burn 10,000 XP
(-100 DC), eleven additional casters contributing 9th-level spell slots
(-187 DC), ten additional casters contributing 8th-level spell slots
(-150 DC), ten additional casters contributing 1st-level spell slots
(-10 DC).
    <p>This spell creates a new creature: an achaierai. When first
created, the achaierai is Medium, but it grows to Large size in 1d4
days. A created achaierai does not possess the treasure, culture, or
specific knowledge of a normal achaierai. If released to be among its
own kind, it quickly picks up achaierai traits and alignment. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP. </p>
    <h6><a id="peripety"></a>Peripety </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 12 hours 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 243,000 gp; 5
days; 9,720 XP. Seed: reflect (DC 27). 
    <p>Ranged attacks targeted against the character rebound on the
original attacker. Any time during the duration, five attacks are
automatically reflected back on the original attacker; the character
decides which attacks before damage is rolled. The reflected attack
rebounds on the attacker using the same attack roll. Once five attacks
are so reflected, the spell ends. </p>
    <h6><a id="pestilence"></a>Pestilence </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration, Necromancy </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 104 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, Ritual,
XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 1,000-ft.-radius
hemisphere 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 936,000 gp;
19 days; 37,440 XP. Seed: afflict (DC 19). Factors: additional target
type (plants) (+10 DC). change target to area (+10 DC), change 20-ft.
radius to 1,000-ft. radius (+200 DC), disease effects (as per contagion
spell) (ad hoc +21 DC). Mitigating factors: casting time increased by 9
minutes (-18 DC), two additional casters contributing epic spell slots
(-38 DC), burn 10,000 XP (-100 DC).
    <p>When pestilence is successfully cast, a wave of illness
radites outward from the site of the ritual, instantly infecting every
living thing in the area with the debilitating disease known as slimy
doom. Within 24 hours, everything in the area begins to show signs of
rot and decay.</p>
    <p>Each day that a victim fails a Fortitude save, it takes 1d4
points of temporary Constitution damage. If the victim then fails a
second save, 1 point of that damage is permanent drain. If the victim
succeeds at the first saving throw of the day on consecutive days, he
or she has recovered from the disease. This magical form of the disease
is not contagious and will not spread beyond those initially infected.
Fruits and vegetables infected with slimy doom are unfit for
consumption, as are disease-ridden livestock. This is a ritual spell
requiring two other spellcasters, each of whom must expend an unused
epic spell slot for the casting. The primary caster must also burn
10,000 XP. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP. </p>
    <h6><a id="rain-of-fire"></a>Rain of Fire </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Fire] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 50 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 2-mile-radius
emanation 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex
negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 450,000 gp; 9
days; 18,000 XP. Seeds: energy (fire) (DC 19), energy (weather) (DC
19). Factor: change rain to wisps of flame (ad hoc +12 DC). 
    <p>This spell summons a swirling thunderstorm that rains fire
rather than raindrops down on the character and everything within a
two-mile radius of him or her. Everything caught unprotected or
unsheltered in the flaming deluge takes 1 point of fire damage each
round. A successful Reflex save results in no damage, but the save must
be repeated each round. Unless the ground is exceedingly damp, all
vegetation is eventually blackened and destroyed, leaving behind a
barren wasteland similar to the aftermath of a grass or forest fire.
The fiery storm is stationary and persists even if the caster leaves. </p>
    <h6><a id="raise-island"></a>Raise Island </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 38 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, XP,
Ritual 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 65 days, 11
minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 100-ft.-radius
hemispherical island 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 360,000 gp; 8
days; 14,400 XP. Seed: conjure (DC 21). Factors: change area to 10-ft.
radius, 30-ft. high cylinder (+2 DC), change radius to 100 ft. (+40
DC), change height to 1,000 feet (+133 DC), permanent (x5 DC).
Mitigating factors: increase casting time by 10 minutes (-20 DC),
increase casting time by 65 days (-130 DC), nineteen additional casters
contributing epic spell slots (-361 DC), one additional caster
contributing one 6th-level spell slot (-11 DC), burn 2,000 XP per epic
caster (-400 DC), spell only works on liquid (ad hoc -20 DC).
    <p>The character can literally raise a new island from out of the
sea, bringing to the surface a sandy or rocky but otherwise barren
protrusion that is solid, stable, and permanently established. The
island is roughly circular and about 200 feet in diameter. Raise island
only works if the ocean is less than 1,000 feet deep where the spell is
cast.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,000 XP. </p>
    <h6><a id="ruin"></a>Ruin </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, X P 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 full
round 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 12,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature, or
up to a 10-foot cube of nonliving matter 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 243,000 gp; 5
days; 9,720 XP. Seed: destroy (DC 29). Factor: reduce casting time by 9
rounds (+18 DC). Mitigating factor: burn 2,000 XP (-20 DC). 
    <p>The character deals 20d6 points of damage to a single target
within range and line of sight. If the target is reduced to -10 hit
points or less (or a construct, object, or undead is reduced to 0 hit
points), it is utterly destroyed as if disintegrated. Only a trace of
fine dust remains. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 2,000 XP. </p>
    <h6><a id="safe-time"></a>Safe Time </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration [Teleportation] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 64 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You or creature
touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Contingent
until expended, then 1 round of safe time 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 576,000 gp;
12 days; 23,040 XP. Seed: transport (DC 27). Factors: move to time
stream (+8 DC), reduce static time to 1 round (ad hoc +4 DC), activates
when you would otherwise take 50 or more points of damage (+25 DC). 
    <p>Safe time can move the character (or the target) out of harm&#8217;s
way by shunting him or her into a static time stream. Once cast, the
spell remains quiescent and does not activate until the trigger
conditions have been met. Each day it remains untriggered, it uses up
an epic spell slot, even if you cast it on another creature. Once
triggered, the spell is expended normally. When the character would
otherwise be subject to any instantaneous effect that would deal him or
her 50 or more points of damage, he or she is instead transported to a
static time stream where time ceases to flow. The character&#8217;s condition
becomes fixed-no force or effect can harm him or her until 1 round of
real time has passed. Thus, the character avoids the damage he or she
would otherwise receive, but the character also misses out on one round
of activity. To the character, no time passes at all, but to onlookers
who are part of real time, the character stands frozen and fixed in
space for 1 full round. </p>
    <h6><a id="soul-dominion"></a>Soul Dominion </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination, Enchantment
(Compulsion) [Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 72 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One other living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 minutes (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 648,000 gp;
13 days; 25,920 XP. Seeds: contact (DC 23), reveal (DC 19), compel (DC
19). Factors: apply to all five senses (+8 DC), total compulsory
control (+10 DC), stricter compulsion of any creature (ad hoc +11 DC).
Mitigating factor: increase casting time by 9 minutes (-18 DC). 
    <p>When a character casts this spell, he or she is temporarily
able to take control of another sentient creature with whom the
character is familiar (by meeting, observing, or successfully scrying
the subject). The target receives a Will save, and if successful,
prevents the character from making the telepathic connection. The
target is aware of the attempted takeover as a strange, momentary
tingling. If the Will save fails, the character is able to control the
subject&#8217;s body as if it were his or her own, hearing, seeing, feeling,
smelling, and tasting everything the target senses. Once the character
dismisses the spell or its duration ends, the target resumes control of
its body, fully aware of all events that occurred, having been a
helpless witness trapped inside its own body. The target knows the name
and general nature of its possessor if it succeeds at an additional
Will saving throw. A character cannot control undead or incorporeal
creatures with soul dominion.</p>
    <h6><a id="soul-scry"></a>Soul Scry </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 55 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One other living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 minutes (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 495,000 gp;
10 days; 19,800 XP. Seeds: contact (DC 23), reveal (DC 19), conceal (DC
17). Factors: apply to all five senses (+8 DC), conceal detection (ad
hoc +6 DC). Mitigating factor: increase casting time by 9 minutes (-18
DC). 
    <p>When a character casts this spell, he or she is temporarily
able to tap the consciousness of another sentient creature with whom
the character is familiar (by meeting, observing, or successfully
scrying the subject), experiencing everything he or she does with all
five senses. The target receives a Will save, and if successful,
prevents the character from making the telepathic connection. Whether
the saving throw is successful or not, the target is unaware of the
attempted intrusion. Once the subject is tapped, the character is able
to hear, see, feel, smell, and taste everything the subject senses. The
character cannot control the subject, however. The character can only
see what the subject chooses to look at, and the character tastes
something only if the subject eats or drinks it during the spell&#8217;s
duration. During this time, the character&#8217;s own body remains in a
trance-like state. If the subject takes damage, the character senses
the injuries, although his or her own body does not actually suffer any
ill effects. If the subject is knocked unconscious or killed, the spell
immediately ends. </p>
    <h6><a id="spell-worm"></a>Spell Worm </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion)
[Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 45 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours or
until completed 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 405,000 gp; 9
days; 16,200 XP. Seed: compel (DC 19). Factors: unobtrusive (ad hoc +6
DC), 1-action casting time (+20 DC). 
    <p>On a failed save, the subject must spend a standard action
each round abandoning his or her highest-level spell (or losing his or
her highest-level unused spell slot). Each round, the subject
eliminates another spell or spell slot, moving to lower-level spells
once all the higher-level spells are gone. In the case of prepared
spells, the subject decides which spells to abandon at each level. If
the subject has more than one standard action allowed in the round, he
or she may spend those actions as he or she desires. The subject
doesn&#8217;t realize the spells or spell slots are gone until he or she
tries to cast a spell and finds it unavailable. Abandoning a spell slot
or losing a spell is standard action, but it does not draw an attack of
opportunity. It is a purely mental exercise not obvious to observers. </p>
    <h6><a id="summon-behemoth"></a>Summon Behemoth </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 72 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Summoned creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 648,000 gp;
13 days; 25,920 XP. Seed: summon (DC 14). Factors: summon CR 21
creature (DC +38), 1-action casting time (+20 DC). 
    <p>The character can summon a behemoth to attack his or her
enemies. It appears where the character designates and acts
immediately, on the character&#8217;s turn. It attacks the character&#8217;s
opponents to the best of its ability. If the character can communicate
with the creature, he or she can direct it not to attack, to attack
particular enemies, or to perform other actions. Summoned creatures act
normally on the last round of the spell and disappear at the end of
their turn. </p>
    <h6><a id="superb-dispelling"></a>Superb Dispelling </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 59 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature or
object 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 531,000 gp;
11 days; 21,240 XP. Seed: dispel (DC 19). Factors: additional +30 to
dispel check (+30 DC), 1-action casting time (+20 DC). Mitigating
factor: 10d6 backlash (-10 DC). 
    <p>As greater dispel magic, except that the maximum bonus on the
dispel check is +40, and the character takes 10d6 points of backlash
damage. </p>
    <h6><a id="time-duplicate"></a>Time Duplicate </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration [Teleportation] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 71 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 free
action 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> You 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> None
(harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 639,000 gp;
13 days; 25,560 XP. Seed: transport (to move future you back in time 1
round) (DC 27). Factors: move to time stream (+8 DC), stretch the base
temporal effect (ad hoc +8 DC), quickened (+28 DC). 
    <p>The character snatches him or her self from 1 round in the
future, depositing this future self in an adjacent space as a free
action that counts as a quickened spell. The character&#8217;s future self is
technically only a possible future self (the time stream is a maelstrom
of multiple probabilities), but snatching that future self from 1 round
in the future collapses probability, and the possible future becomes
the definite future. The character and his or her future self are both
free to act normally this round (the character has already used up the
limit of one quickened spell per round, but his or her duplicate
hasn&#8217;t). The future self has all the resources the character has at the
moment he or she finishes casting time duplicate. Because the future
self was previously only a possibility, his or her resources are not
depleted as a result of whatever might occur this round (even if the
character dies this round). Likewise, he or she doesn&#8217;t have any
special knowledge of what might occur during this round. Because the
future self is still part of the time stream, the round it spends with
the character is a round it misses in its own future. Because the
chracter&#8217;s future duplicate is also the character, the character misses
the next round as well. He or she simply isn&#8217;t there. Tampering with
the time stream is a tricky business. Here is a round-by-round summary.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Round One:</span> The
character casts time duplicate, the future self from round two arrives,
and both act normally. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Round Two:</span> The future
self-the character-gets snatched back in time to help the past self.
During this round, there are no versions of the character present. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Round Three:</span> The
character rejoins the time stream. The character arrives in the same
location and condition that the future self ended with at the end of
the first round. Any resources (spells, damage, staff charges) the
future self used up in round one are gone for real. Record them now.
Using this spell to snatch a single future self stretches time and
probability to its limit; more powerful versions of time duplicate are
not possible. A character cannot bring more than a single future
version of him or her self back at one time, nor can a character snatch
a version of him or her from farther in the future. </p>
    <h6><a id="vengeful-gaze-of-god"></a>Vengeful Gaze of God </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 419 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 12,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature, or
up to a 10-foot cube of nonliving matter in line of sight 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 3,771,000 gp;
76 days; 150,840 XP. Seed: destroy (DC 29). Factor: increase damage to
305d6 (+570 DC), 1-action casting time (+20 DC). Mitigating factor:
200d6 backlash (-200 DC). 
    <p>The target of this spell is subject to 305d6 points of damage
(or half of that if a Fortitude save succeeds). If the target is
reduced to -10 hit points or less (or a construct, object, or undead is
reduced to 0 hit points), it is utterly destroyed as if disintegrated,
leaving behind only a trace of fine dust. The caster is likewise dealt
200d6 points of damage</p>
    <h6><a id="verdigris"></a>Verdigris </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 58 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 100-ft.-radius
hemisphere 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 24 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 522,000 gp;
11 days; 20,880 XP. Seed: conjure (DC 21). Factors: change Area to
20-ft.-radius hemi-sphere (+2 DC), increase radius to 100 ft. (+16 DC),
deal 10d6 damage during growth (ad hoc +19 DC). 
    <p>This spell creates a tsunami of grass, shrubs, and trees that
overgrows the area like a tidal wave. The plant growth creeps and curls
across every-thing in the area, ensnaring it and coiling around it as
if it had been growing there for a century or more. Creatures in the
area must make a Reflex saving throw to avoid the fast-moving growth,
which otherwise deals 10d6 points of damage from the crushing press.
Buildings are engulfed and they likewise take 10d6 points of damage.
Those destroyed by the damage have their foundations uprooted and walls
crumbled. The plant growth remains for 24 hours, after which it
vanishes. </p>
    <a id="verdigris-tsunami"></a><h6>Verdigris Tsunami </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 170
    <span style={{
      "fontWeight": "bold"
    }}>&nbsp;Components:</span> V, S,
Ritual, XP 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 1,500 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 1,000-ft.-radius
hemisphere 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <span style={{
      "fontWeight": "bold"
    }}>To Develop:</span> 1,530,000 gp;
31 days; 61,200 XP. Seed: conjure (DC 21). Factor: change area to
20-ft.-radius hemisphere (+2 DC), increase radius to 1,000 ft. (+196
DC), increase range to 1,500 ft. (+8 DC), deal 10d6 damage during
growth (ad hoc +19 DC), increase damage to 40d6 (+60 DC), permanent (x5
DC). Mitigating factors: increase casting time by 9 minutes (-18 DC),
eleven additional casters contributing 6th-level spell slots (-121 DC),
three additional casters contributing 4th-level spell slots (-21 DC),
burn 10,000 XP per 6th-level spell contributor plus caster (-1,200 DC).
    <p>This spell creates a tsunami of grass, shrubs, and trees that
overgrows the area like a tidal wave. The plant growth creeps and curls
across every-thing in the area, ensnaring it and coiling around it as
if it had been growing there for a century or more. Creatures in the
area must make a Reflex saving throw to avoid the fast-moving growth,
which otherwise deals 40d6 points of damage from the crushing press.
Buildings are engulfed and they likewise take 40d6 points of damage.
Those destroyed by the damage have their foundations uprooted and walls
crumbled. The plant growth is permanent. This is a ritual spell
requiring fourteen other spellcasters, each of whom must contribute an
unused 6th-level spell slot to the casting. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP.
    </p>
    <a id="non-epic-spell"></a><h5>NON-EPIC SPELL</h5>
    <a id="genesis"></a><h6>Genesis</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Sor/Wiz 9,
Creation 9
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, M, XP
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 week (8
hours/day)
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 180 ft. (see text)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> A demiplane
coterminous with the Ethereal Plane, centered on your location
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>The spellcaster creates a finite plane with limited access: a
demiplane. Demiplanes created by this power are very small, very minor
planes.</p>
    <p>A character can only cast this spell while on the Ethereal
Plane. When he or she casts the spell, a local density fluctuation
precipitates the creation of a demiplane. At first, the fledgling plane
grows at a rate of 1 foot in radius per day to an initial maximum
radius of 180 feet as it rapidly draws substance from surrounding
ethereal vapors and protomatter.</p>
    <p>The spellcaster determines the environment within the
demiplane when he or she first casts genesis, reflecting most any
desire the spellcaster can visualize. The spellcaster determines
factors such as atmosphere, water, temperature, and the general shape
of the terrain. This spell cannot create life (including vegetation),
nor can it create construction (such as buildings, roads, wells,
dungeons, and so forth). The spellcaster must add these things in some
other fashion if he or she desires. Once the basic demiplane reaches
its maximum size, the spellcaster can continue to cast this spell to
enlarge the demiplane, adding another 180 feet of radius to the
demiplane each time.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span> A
crystalline sphere.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 5,000 XP.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      